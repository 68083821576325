import React, { useState } from "react";
import SEO from "../components/SEO";
import Modal from "react-modal";
import Layout from "../components/Layout";
import Leila from "../assets/img/leila.jpg";

import Houda from "../assets/img/houda.jpg";

import Joseph from "../assets/img/Joseph_Ruddy.jpg";

import Nabil from "../assets/img/nabil-2.jpg";

import Nicole from "../assets/img/Nicole_biophoto.jpg";

import Tarek from "../assets/img/tarek.jpg";

const OurTeam = () => {
  const [modalIsOpen, setIsOpen] = useState(false);
  const [name, setName] = useState("");
  const [title, setTitle] = useState("");
  const [description, setDescription] = useState("");
  const [image, setImage] = useState("");

  const openModal = (member) => {
    setIsOpen(true);
    setDescription(member.description);
    setTitle(member.title);
    setName(member.name);
    setImage(member.image);
  };

  const closeModal = () => {
    setIsOpen(false);
  };
  const customStyles = {
    content: {
      backgroundColor: "transparent",
      position: "inherit",
      border: "none",
    },
  };
  const team = [
    {
      name: "Leila Calnan",
      position: "Chief of Party",
      description: `Leila Calnan is the chief of party of Visit
    Tunisia. She has worked for over 25 years in
    international development, specializing in
    sustainable tourism development in destinations
    such as Ghana, Greece, Jordan, Micronesia,
    Mozambique, Namibia, Palestine, Peru, Sri Lanka,
    and Tanzania. For four years, she served as the team
    leader for the highly successful North American Destination
    Marketing (NADM) project funded by the Millenium Challenge 
    Corporation (MCC), helping to promote Namibia as a
    tourism destination for the North American market.
    She holds an MA in Tourism Administration from
    George Washington University, an MA in
    International Development from American
    University, and a BA in Sociology from Boston
    College. Ms. Calnan has two daughters who also
    love to travel and are excited to explore the
    wonders of Tunisia together. She is fascinated by
    Tunisia’s rich Amazigh culture and hopes to share
    it with other curious travelers. Empowering women
    in the tourism sector is one of her special
    interests and will be a priority of her work.`,
      image: Leila,
    },
    {
      name: "Nicole Skarpness",
      position: "DDeputy Chief of Party and Destination Marketing Team Leader",
      description: `Nicole Skarpness is the deputy chief of party of the USAID Visit Tunisia Activity. 
      With more than 11 years of experience in international development, Nicole has worked on USAID-funded
      programs in destinations such as Tunisia, Libya, Syria, Iraq, Ukraine, Myanmar, Guatemala, Honduras,
      Zambia, Rwanda, and Uganda. She recently served as the subcontracts and grants team lead on the Ukraine
      Competitive Economy Program based in Kyiv, supporting private sector development and SMEs/start-ups in the IT,
      tourism, and fashion industries through a $12.5 million grants fund. She previously oversaw implementation
      of Chemonics International’s Global Ethics training program and helped lead corporate communications,
      training, and change management of the company’s Digital Transformation Initiative. She moonlights as
      a yoga teacher, speaks fluent Spanish and basic Portuguese + Russian, collects books and art from the 60+
      countries she’s traveled to, and generally prefers to be outdoors hiking, biking, swimming, and staying active.
      She’s passionate about independent and adventure travel and is excited to explore the many outdoor and adventure
      opportunities around Tunisia. She has a B.A. in government and sociology from the College of William and Mary.`,
      image: Nicole,
    },

    {
      name: "Houda Nefzaoui",
      position: "Business Enabling Environment Team Leader",
      description: `Houda Nefzaoui is Visit Tunisia’s business
      enabling environment team leader. Since 1999, Ms.
      Nefzaoui has worked in international financial and
      investment law, foreign investment protection,
      business law, legal reforms, and project
      management in both the public and private sectors.
      Prior to joining Visit Tunisia, she served as the
      chief of support for the Tunisia Investment
      Authority (TIA). Before that, she served for more
      than 15 years as a senior legal advisor for the
      president of the Tunisian government, providing
      counsel on investment reform, economic law,
      international finance, and infrastructure
      financing. In addition, she has worked as a legal
      advisor for an offshore bank in Tunis and
      negotiated on behalf of Tunisia on bilateral
      investment treaties. Ms. Nefzaoui holds a
      bachelor’s degree in Corporate Law and a master's
      degree in International Contracts and Investment
      Law from the University of Law and Political
      Sciences of Tunis, as well as a master's degree in
      Public Management from the Tunisian National
      School of Administration.`,
      image: Houda,
    },
    {
      name: "Tarak Bey",
      position: "Tourism Products & Experiences Team Leader",
      description: `Tarak started his career in 2007 with BNP Paribas CIB in equity derivatives, 
      where he worked in the bank's trading floors in Paris and London. He then moved to the UAE, 
      where he assisted in the implementation of Mashreq Bank's Private Banking offering in Dubai 
      and dynamically managed large portfolios of diversified asset classes on behalf of UHNWI. 
      In 2011 and following the Jasmine Revolution, he decided to move to Tunisia where he first 
      joined Attijari Finances Corp, the investment banking subsidiary of the Attijariwafa Bank Group, 
      as Financial Advisor, then returned to the BNP Paribas Group with the mission of implementing 
      the Wealth Management department in Tunisia. In 2017, Mr. BEY was appointed Advisor to the Minister
       of Economy & Planning and was tasked, as National Coordinator of the Compact program with the US 
       Agency Millennium Challenge Corporation (MCC), leading all aspects of the development of this 
       ambitious $500M infrastructure program. He then served as Investment and PPP Specialist 
       for the USAID Visit Tunisia Activity and Country Representative for Proparco – AFD Group. 
       Mr. Bey holds a bachelor's degree in applied mathematics & statistics & economics as well 
       as a master's degree in financial & statistical engineering, both from Paris Dauphine – PSL University. 
       Tarak joined the USAID Visit Tunisia project with more than 15 years of experience in the field of international development and investment banking.`,
      image: Tarek,
    },
    {
      name: "Nabil Gasmi",
      position: "Partner Engagement Specialist Team Manager",
      description: `Nabil Gasmi is Visit Tunisia’s partner engagement specialist team manager.
      He works with public and private stakeholders to facilitate technical assistance to
      assist in developing unique tourism products and experiences. Mr. Gasmi has nearly two decades
      of experience in geographic analysis focusing on Tunisia and is also specialized in sustainable
      tourism development, including the design of thematic tourism itineraries. He has worked for
      many international cooperation projects on the development of sustainable tourism in Tunisia,
      including with the Harvard Center for Health and Global Environment and the Germany Agency for
      International Cooperation. He holds a PhD in geography from the University of Tunis.
      He is married to Nozha Hachicha and has three daughters, Nour El Houda, Noha, and Nada.`,
      image: Nabil,
    },

    {
      name: "Joseph Ruddy",
      position:
        "Enabling Environment and Tourism <br /> Human Capital Development Advisor",
      description: `Joseph Ruddy is Visit Tunisia’s enabling
      environment and tourism human capital development
      advisor. He supports project activities to improve
      professional competencies and quality standards in
      the tourism industry. For more than 30 years, Dr.
      Ruddy has worked in international development,
      specializing in human capital development,
      education and training development, academic
      quality assurance, and industry development. He
      has written and presented more than 140 research
      papers on these topics and implemented management
      projects throughout the world, including for
      USAID, the World Bank, and the European Union. Dr.
      Ruddy holds a PhD in Management Development from
      the University of Salford, UK, a master’s and
      bachelor’s degree in Management from University of
      Dublin – Trinity College, and a graduate diploma
      in Advanced Marketing from the Dublin College of
      Marketing (TUD). He also holds a Certificate in
      Higher Education (Cert Ed) from the University of
      Leeds. Outside of work, Dr. Ruddy enjoys
      countryside hiking and he looks forward to
      exploring Tunisia’s northern Tabarka region.`,
      image: Joseph,
    },
  ];

  // for Nabil CSS is .our-team .overlay .the-team .member:nth-of-type(6) make sure to change it if needed
  return (
    <Layout>
      <SEO title="Our Team | Visit Tunisia Project" />
      <main role="main">
        <div className="container-fluid">
          <div className="region region-content">
            <div data-drupal-messages-fallback className="hidden" />
            <div
              id="block-jobs-front-content"
              className="block block-system block-system-main-block"
            >
              <div className="views-element-container">
                <div className="view our-team view-our-team view-id-our_team view-display-id-page_1 js-view-dom-id-9bbc2b4960505ca6bf74c1141726a032498540b7e49b965814f3a5b7147589f0">
                  <div className="overlay">
                    <div className="container">
                      <h2>Our Team</h2>
                      <div className="the-team d-flex flex-wrap">
                        {team.map((member, index) => (
                          <div
                            key={index}
                            className="member aos-animate"
                            data-aos="fade-up"
                          >
                            <img
                              src={member.image}
                              width={288}
                              height={422}
                              alt={member.name}
                              typeof="Image"
                              style={{ filter: "grayscale(100%)" }}
                            />
                            <div className="member-details">
                              <span>{member.name}</span>
                              <small
                                dangerouslySetInnerHTML={{
                                  __html: member.position,
                                }}
                              ></small>
                              <button
                                style={{
                                  backgroundColor: "transparent",
                                  border: "none",
                                  textAlign: "left",
                                  color: "#a3c4ee",
                                }}
                                onClick={() => openModal(member)}
                              >
                                Show more
                              </button>
                            </div>
                          </div>
                        ))}
                      </div>
                      <div
                        className="modal fade"
                        id="membermodal"
                        tabIndex={-1}
                        role="dialog"
                        aria-labelledby="membermodal"
                        aria-hidden="true"
                        style={{ maxHeight: "50vh", overflow: "auto" }}
                      >
                        <div className="modal-dialog modal-lg">
                          <div className="modal-content">
                            <div className="modal-header">
                              <button
                                type="button"
                                className="close"
                                data-dismiss="modal"
                                aria-label="Close"
                              >
                                <span aria-hidden="true">×</span>
                              </button>
                            </div>
                            <div className="modal-body" />
                          </div>
                        </div>
                      </div>
                    </div>
                  </div>
                </div>
              </div>
            </div>
          </div>
        </div>
        <Modal
          isOpen={modalIsOpen}
          onRequestClose={closeModal}
          style={customStyles}
        >
          <div
            className="modal fade show"
            id="membermodal"
            tabIndex={-1}
            aria-labelledby="membermodal"
            style={{
              display: "block",
              paddingRight: "8px",
              background: "transparent",
              maxHeight: "100vh",
              overflow: "auto",
            }}
            aria-modal="true"
            role="dialog"
          >
            <div
              className="modal-dialog modal-lg"
              style={{ transform: "translate(0, 35%)" }}
            >
              <div className="modal-content">
                <div className="modal-header">
                  <button
                    type="button"
                    className="close"
                    data-dismiss="modal"
                    aria-label="Close"
                    onClick={closeModal}
                  >
                    <span>×</span>
                  </button>
                </div>
                <div className="modal-body">
                  <div className="member">
                    <img
                      src={image}
                      width="288"
                      height="422"
                      alt={name}
                      typeof="Image"
                      style={{ filter: "grayscale(100%)" }}
                    />

                    <div className="member-details">
                      <span>{name}</span>
                      <small>{title}</small>
                      <p></p>
                      <div
                        dangerouslySetInnerHTML={{ __html: description }}
                      ></div>
                    </div>
                  </div>
                </div>
              </div>
            </div>
          </div>
        </Modal>
      </main>
    </Layout>
  );
};

export default OurTeam;
